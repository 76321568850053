import autocomplete from 'autocompleter'

const load = () => {
  let input = document.getElementById("place_title")

  if (!input) {
    return false
  }

  const coords = {
    latitude: 'lat',
    longitude: 'lng'
  }

  autocomplete({
    input: input,
    debounceWaitMs: 150,
    minLength: 2,
    preventSubmit: true,
    fetch: (text, update) => {
      fetch('/admin/addresses?q=' + text.toLowerCase())
        .then(response => response.json())
        .then(data => {
          update(Array.from(data).map(elem => {
            return { ...elem, label: elem.label, value: elem.value }
          }))
        })
    },
    customize: (input, rect, container) => {
      container.style.maxHeight = "200px"
    },
    onSelect: (item) => {
      input.value = item.value

      fetch('/admin/addresses/' + item.place_id)
        .then(response => response.json())
        .then(data => {
          for (const letter of Object.keys(coords)) {
            document.getElementById(`place_${letter}`).value = data.geometry.location[coords[letter]]
          }
        })
    }
  })
}

if (document.readyState == 'loading') {
  document.addEventListener("turbolinks:load", load)
} else {
  load()
}
